import { Middleware } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export const logger: Middleware<{}, RootState> = store => next => action => {
  if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test") {
    console.group(action.type);
    console.info("dispatching", action);
    let result = next(action);
    console.log("next state", store.getState());
    console.groupEnd();
    return result;
  }
  return next(action);
};
