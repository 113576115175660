import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { Group } from "../Interfaces";

export interface PairedGroupState {
  group: Group | null;
}

const initialState: PairedGroupState = {
  group: null
};

export const pairedGroupSlice = createSlice({
  name: "pairedGroup",
  initialState,
  reducers: {
    addGroup: (state, action) => {
      state.group = action.payload;
    },
    removeGroup: (state) => {
      state.group = null;
    }
  }
});

export const {addGroup, removeGroup} = pairedGroupSlice.actions;

export const selectPairedGroup = (state: RootState) => state.pairedGroup.group;

export default pairedGroupSlice.reducer;
