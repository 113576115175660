import Heading from "../../ui/Heading";
import { ButtonVariants } from "../../ui/ButtonConstants";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { CheckboxWithLabel, TextField } from "formik-mui";
import { boolean, number, object, ref, string } from "yup";
import { useGetCountriesQuery, useRegisterMutation } from "../../services/apiClient";
import { Country } from "../../Interfaces";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../RouteConstants";
import { login } from "../../slices/authSlice";
import { useAppDispatch } from "../../app/hooks";

interface Values {
  first_name: string;
  last_name: string;
  email: string;
  nationality: number;
  sex: "m" | "f" | string;
  password: string;
  password_confirmation: string;
  accepted_terms: boolean;
}

function Register() {
  const { data, isLoading: countriesLoading,  } = useGetCountriesQuery(null);
  const [register, { isLoading: registering }] = useRegisterMutation();
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const submit = (values: Values, { setSubmitting, setFieldError }: FormikHelpers<any>) => {
    if (!registering) {
      register(values).unwrap()
        .then(() => {
          dispatch(login({ email: values.email, password: values.password }))
            .then((response: any) => {
              setSubmitting(false);
              if (response.type === "auth/login/fulfilled") {
                navigate(Routes.CREATE_GROUP);
              }
            });
        })
        .catch(error => {
          setSubmitting(false);
          Object.keys(error.data.errors).forEach(key => {
            setFieldError(key, error.data.errors[key][0]);
          });
        });
    }
  };

  let initialValues: Values = {
    first_name: "",
    last_name: "",
    email: "",
    nationality: 0,
    sex: "",
    password: "",
    password_confirmation: "",
    accepted_terms: false
  };
  let countries: Array<any> = [];
  if (data && !countriesLoading) {
    countries = data.map((country: Country) => <MenuItem value={country.id} key={country.id}>
      {country.name}
    </MenuItem>);
  }
  return (<div className={"flex flex-col min-h-screen"}>
    <Heading>Create Account</Heading>
    <Formik
      initialValues={initialValues}
      validationSchema={object({
        first_name: string().required("First name is required"),
        last_name: string().required("Last name is required"),
        email: string().required("Email address is required").email("Email address must be a valid email address"),
        nationality: number().required("Nationality is required"),
        sex: string().required("Sex is required"),
        password: string().required("Password is required"),
        password_confirmation: string().required("Please confirm your password").oneOf([ref("password"), null], "Passwords don't match"),
        accepted_terms: boolean().isTrue("You need to accept the terms and conditions")
      })}
      onSubmit={submit}
    >
      {({ submitForm, isSubmitting, setFieldValue, values }) => (
        <Form className={"grow flex flex-col justify-center align-middle max-w-md mx-auto w-full"}>
          <div className={"grow"}>
            <h1 className={"text-lg font-bold text-black mb-4 mt-12"}>User Details</h1>
            <Field component={TextField} id={"registerFirstName"} name={"first_name"} label="First name" variant="outlined" required fullWidth margin={"dense"} />
            <Field component={TextField} id={"registerLastName"} name={"last_name"} label="Last name" variant="outlined" required fullWidth margin={"dense"} />
            <Field component={TextField} id={"registerEmailAddress"} name={"email"} label="Email address" variant="outlined" type={"email"} required fullWidth
                   margin={"dense"} />
            <FormControl fullWidth margin={"dense"}
                         variant="outlined"
                         required>
              <InputLabel id={"register-nationality-label"}>Nationality</InputLabel>
              <Field component={Select}
                     labelId={"register-nationality-label"}
                     id={"registerNationality"}
                     name={"nationality"}
                     label={"Nationality"}
                     value={values.nationality}
                     data-testid={'nationality'}
                     onChange={(e: any) => setFieldValue("nationality", e.target.value)}
              >
                <MenuItem disabled value={0}>Please select a country</MenuItem>
                {countries}
              </Field>
            </FormControl>
            <FormControl fullWidth margin={"dense"}
                         variant="outlined"
                         required>
              <InputLabel id={"register-sex-label"}>Sex</InputLabel>
              <Field component={Select}
                     labelId={"register-sex-label"}
                     id={"register-sex"}
                     name={"sex"}
                     label={"Sex"}
                     value={values.sex}
                     data-testid={'sex'}
                     onChange={(e: any) => setFieldValue("sex", e.target.value)}
              >
                <MenuItem disabled>Please select a sex</MenuItem>
                <MenuItem value={"m"}>Male</MenuItem>
                <MenuItem value={"f"}>Female</MenuItem>
              </Field>
            </FormControl>
            <Field component={TextField} id={"registerPassword"} name={"password"} label="Password" variant="outlined" type={"password"} required fullWidth margin={"dense"} />
            <Field component={TextField} id={"registerConfirmPassword"} name={"password_confirmation"} label="Confirm password" variant="outlined" type={"password"} required
                   fullWidth
                   margin={"dense"} />
            <Field type={"checkbox"}
                   component={CheckboxWithLabel}
                   id={"registerAcceptTermsAndConditions"}
                   name={"accepted_terms"}
                   data-testid={'accept-terms'}
                   Label={{
                     label: <>I agree to the <a href={"https://brawn.co.uk/terms-and-conditions"} target={"_blank"} rel={"nofollow noreferrer noopener"}
                                                className={ButtonVariants.link}>terms &amp; conditions</a></>
                   }} />
          </div>
          <div className={"py-5"}>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              type={"submit"}
              fullWidth
              data-testid={"register-submit-button"}
              size={"large"}
            >Continue</Button>
          </div>
        </Form>
      )}
    </Formik>
  </div>);
}

export default Register;
