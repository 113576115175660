
export const assetUrl = (url: string, size: keyof typeof sizes = "md") => {
  const sizes = {
    xs: 20,
    sm: 30,
    md: 45,
    lg: 60,
    xl: 90,
    xxl: 180
  };
  const imageRequest = JSON.stringify({
    bucket: process.env.REACT_APP_AWS_IMAGE_BUCKET,
    key: url,
    edits: {
      resize: {
        width: sizes[size],
        height: sizes[size],
        fit: "cover"
      }
    }
  });
  return process.env.REACT_APP_AWS_IMAGE_ENDPOINT + "/" + btoa(imageRequest);
};
