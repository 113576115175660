import { Action, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import authReducer from "../slices/authSlice";
import pairCodeReducer from "../slices/pairCodeSlice";
import pairedGroupReducer from "../slices/pairedGroup"
import thunkMiddleware from "redux-thunk";
import { logger } from "../middleware/logger";
import { brawnApi } from "../services/apiClient";
import { setupListeners } from "@reduxjs/toolkit/query";
import { storePairCode } from "../middleware/storePairCode";

export const setUpStore = () => configureStore({
  reducer: {
    auth: authReducer,
    pairCode: pairCodeReducer,
    pairedGroup: pairedGroupReducer,
    [brawnApi.reducerPath]: brawnApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(
        thunkMiddleware
      )
      .concat(logger, storePairCode, brawnApi.middleware)
});

export const store = setUpStore();

setupListeners(store.dispatch);
const rootReducer = combineReducers({ auth: authReducer, pairCode: pairCodeReducer, pairedGroup: pairedGroupReducer });
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;
