import React from "react";
import PairCodeForm from "../ui/PairCodeForm";
import Heading from "../ui/Heading";
import { useNavigate } from "react-router-dom";
import { Routes } from "../RouteConstants";

function SavePairCode() {
  let navigate = useNavigate();
  return (
    <div className={"flex flex-col min-h-screen"}>
      <Heading>Pair Tablet</Heading>
      <PairCodeForm className={"grow flex flex-col justify-center align-middle max-w-md mx-auto w-full"}
                    onSubmit={() => navigate(Routes.ATTACH_GROUP)}
                    buttonText={"Continue"} />
    </div>
  );
}

export default SavePairCode;
