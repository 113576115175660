import { Navigate, useLocation } from "react-router-dom";
import { Routes } from "./RouteConstants";
import { selectUser } from "./slices/authSlice";
import { useAppSelector } from "./app/hooks";

function GuestRoute({ children }: { children: JSX.Element }) {
  const user = useAppSelector(selectUser);
  let location = useLocation();

  if (user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={Routes.ATTACH_GROUP} state={{ from: location }} replace />;
  }

  return children;
}

export default GuestRoute;
