import { Middleware } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { addCode } from "../slices/pairCodeSlice";

export const storePairCode: Middleware<{}, RootState> = store => next => action => {
  let result = next(action);
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  let code = params.get("code");
  let currentCode = store.getState().pairCode.code;
  if (code && code !== currentCode) {
    store.dispatch(addCode(code));
  }
  return result;
};

