const ButtonVariants = {
  default: "",
  success: "",
  warning: "",
  danger: "",
  primary: "border border-red-500 rounded bg-red-500 text-white py-2 px-3 font-bold text-center focus:bg-red-600 hover:bg-red-600",
  secondary: "border border-red-500 rounded bg-white text-red-500 py-2 px-3 font-bold text-center focus:bg-red-500 hover:bg-red-500 hover:text-white focus:text-white active:text-white",
  link: "text-red-500 hover:text-red-600 underline underline-offset-1",
};

export {ButtonVariants};
