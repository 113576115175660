import Heading from "../ui/Heading";
import { Group } from "../Interfaces";
import React from "react";
import GroupSelector from "../ui/GroupSelector";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { addGroup, selectPairedGroup } from "../slices/pairedGroup";
import { useNavigate } from "react-router-dom";
import { Routes } from "../RouteConstants";

function AttachGroup() {
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let group = useAppSelector(selectPairedGroup);

  React.useEffect(() => {
    if (group) {
      navigate(Routes.CONFIRM_GROUP);
    }
  }, [navigate, group]);

  let handleSelectGroup = (group: Group) => {
    dispatch(addGroup(group));
    navigate(Routes.CONFIRM_GROUP);
  };
  return (
    <div className={"flex flex-col min-h-screen"}>
      <Heading>Select Gym Group</Heading>
      <GroupSelector className={"grow flex flex-col max-w-md mx-auto w-full"} onSelect={handleSelectGroup} />
    </div>
  );
}

export default AttachGroup;
