import Heading from "../ui/Heading";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import { Button } from "@mui/material";
import { number, object, string } from "yup";
import { useAddNewGroupMutation } from "../services/apiClient";
import React from "react";
import GooglePlacesAutoComplete, { GooglePlaceResult } from "../ui/GooglePlacesAutoComplete";
import { addGroup, selectPairedGroup } from "../slices/pairedGroup";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import { Routes } from "../RouteConstants";

interface Values {
  display_name: string;
  location?: GooglePlaceResult;
}

function CreateGroup() {
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let group = useAppSelector(selectPairedGroup);
  if (group) {
    navigate(Routes.CONFIRM_GROUP);
  }
  const [addNewGroup, { isLoading }] = useAddNewGroupMutation();
  const submit = (values: Values, { setFieldError }: FormikHelpers<any>) => {
    if (!isLoading) {
      try {
        let location = values.location;
        let payload = {
          display_name: values.display_name,
          google_places_id: location?.place_id,
          latitude: location?.geometry.location.lat,
          longitude: location?.geometry.location.lng
        };
        addNewGroup(payload).unwrap().then(response => {
          navigate(Routes.CONFIRM_GROUP);
          dispatch(addGroup(response.data));
        });
      } catch (e) {
        if ((e as any).status === 422) {
          if ((e as any).data.errors.display_name) {
            setFieldError("display_name", (e as any).data.errors.display_name[0]);
          }
        }
      }
    }
  };

  return (
    <div className={"flex flex-col min-h-screen"}>
      <Heading>Create Group</Heading>
      <Formik
        initialValues={{ display_name: "" }}
        validationSchema={object({
          display_name: string().required("Group name is required"),
          location: object({
            place_id: string().required(),
            geometry: object({
              location: object({
                lng: number().required(),
                lat: number().required()
              })
            })
          })
            .required("Please select a location")
        })}
        onSubmit={submit}
      >
        {({ submitForm, isSubmitting, setFieldValue }) => {
          return (
            <Form className={"grow flex flex-col justify-center align-middle max-w-md mx-auto w-full"}>
              <div className={"grow flex flex-col justify-center align-middle"}>
                <h1 className={"text-lg font-bold text-black mb-4 mt-12"}>Group Details</h1>
                <Field component={TextField} id={"create-group-name"} name={"display_name"} label="Group name" variant="outlined" required fullWidth
                       margin={"dense"} />
                <Field component={GooglePlacesAutoComplete} id={"create-group-location"} name={"location"} label="Location" variant="outlined" required fullWidth
                       margin={"dense"} onChange={(value: GooglePlaceResult) => setFieldValue("location", value)} />
              </div>
              <div className={"py-5"}>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={isSubmitting}
                  type={"submit"}
                  fullWidth
                  size={"large"}
                  data-testid={'create-group-submit'}
                >Continue</Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CreateGroup;
