import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface PairCodeState {
  code: string;
  error: boolean;
}

const initialState: PairCodeState = {
  code: "",
  error: false
};

export const pairCodeSlice = createSlice({
  name: "pairCode",
  initialState,
  reducers: {
    addCode: (state, action) => {
      state.code = action.payload;
    },
    removeCode: (state) => {
      state.code = "";
    },
    hasError: (state) => {
      state.error = true;
    }
  }
});

export const { addCode, removeCode, hasError } = pairCodeSlice.actions;

export const selectPairCode = (state: RootState) => state.pairCode.code;
export const selectPairCodeError = (state: RootState) => state.pairCode.error;

export default pairCodeSlice.reducer;
