import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Field, Form, Formik, useFormikContext } from "formik";
import { addCode, selectPairCodeError } from "../slices/pairCodeSlice";
import { object, string } from "yup";
import { TextField } from "formik-mui";
import { Button } from "@mui/material";
import React from "react";

interface Values {
  code: string;
}

interface PairFormProps {
  className?: string,
  buttonText?: string,
  onSubmit: (code: string) => void
}

export const failedCodeErrorMessage = "The pairing code you supplied seems to be invalid, please check your tablet, and try again.";
const SetCodeError = () => {
  let error = useAppSelector(selectPairCodeError);
  let { setFieldError } = useFormikContext();
  React.useEffect(() => {
    if (error) {
      setFieldError("code", failedCodeErrorMessage);
    }
  }, [setFieldError, error]);
  if (!error) {
    return null;
  }
  return <p className={"text-red-500"} data-testid={"pair-code-error-message"}>{failedCodeErrorMessage}</p>;
};

function PairCodeForm(props: PairFormProps) {
  let dispatch = useAppDispatch();
  const submit = (values: Values) => {
    dispatch(addCode(values.code));
    props.onSubmit(values.code);
  };

  return (
    <Formik
      initialValues={{ code: "" }}
      validationSchema={object({
        code: string().required("Pair code is required").min(6)
      })}
      onSubmit={submit}
    >
      {({ submitForm, isSubmitting, isValid, submitCount }) => {
        return (
          <Form className={props.className}>
            <div className={"grow flex flex-col justify-center align-middle"}>
              <h1 className={"text-lg font-bold text-black mb-4"}>Save Pair Code</h1>
              <p className={"mb-2"}>You can get the code from your in-gym tablet.</p>
              <Field component={TextField} id={"save-pair-code"} name={"code"} label="Pair code" variant="outlined" required fullWidth
                     margin={"dense"} data-testid={"save-pair-code"} />
              <SetCodeError />
            </div>
            <div className={"py-5"}>
              <Button
                variant="outlined"
                color="primary"
                disabled={isSubmitting}
                type={"submit"}
                fullWidth
                size={"large"}
                data-testid={"save-pair-code-submit"}
              >{props.buttonText}</Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default PairCodeForm;
