export const Routes = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/password/forgot",
  RESET_PASSWORD: "/password/reset/:token",
  CREATE_GROUP: "/group/create",
  ATTACH_GROUP: "/group/attach",
  CONFIRM_GROUP: "/group/confirm",
  SAVE_PAIR_CODE: "/save-pair-code",
  TABLET_PAIRED: "/tablet-paired"
};
