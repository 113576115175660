import Heading from "../ui/Heading";
import React from "react";
import { CheckCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { removeGroup } from "../slices/pairedGroup";
import { removeCode } from "../slices/pairCodeSlice";
import { useAppDispatch } from "../app/hooks";

function GroupTabletPaired() {
  let closeTab = () => {
    if (window.confirm("Close Window?")) {
      window.close();
    }
  };
  let dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(removeGroup());
    dispatch(removeCode());
  });
  return (
    <div className={"flex flex-col min-h-screen"}>
      <Heading>Connected</Heading>
      <div className={"grow flex flex-col justify-center align-middle max-w-md mx-auto w-full"}>
        <div className={"grow flex flex-col mt-4 justify-center text-black"}>
          <CheckCircle className={"text-red-500 mx-auto mb-8"} sx={{ fontSize: "4rem" }} />
          <h3 className={"text-xl font-bold mb-2"}>All done!</h3>
          <p className={"mb-2"}>Please confirm that your in-gym device has connected to your gym group. This can take a couple minutes.</p>
          <p className={"mb-2"}>You may then close this tab.</p>
        </div>
        <div className="py-5">
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            size={"large"}
            onClick={closeTab}
            data-testid={'close-tab-button'}
          >
            Close Tab
          </Button>
        </div>
      </div>
    </div>
  );
}

export default GroupTabletPaired;
