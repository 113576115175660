import React from "react";
import { ReactComponent as BackArrow } from "./../icons/BackArrow.svg";
import { useNavigate } from "react-router-dom";

function Heading(props: React.PropsWithChildren<any>) {
  let navigate = useNavigate();

  const onClick = () => navigate(-1);

  return <div className={"bg-red-500 text-white -mx-16 pt-8 py-6"}>
    <div className={"max-w-md mx-auto w-full"}>
      <button className={"pl-4 md:pl-0 pr-4 py-2 absolute"} data-testid={"back"} onClick={onClick}><BackArrow /></button>
      <h1 className={"text-center text-lg font-bold"}>{props.children}</h1>
    </div>
  </div>;
}

export default Heading;
