import { AxiosStatic } from "axios";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

let apiClient: AxiosStatic;
apiClient = require("axios").default;
apiClient.defaults.baseURL = process.env.REACT_APP_API_URI;
apiClient.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
apiClient.defaults.headers.common["x-brawn-api-version"] = "2021-09-17";
apiClient.defaults.withCredentials = true;

export default apiClient;

export const brawnApi = createApi({
  reducerPath: "brawnApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URI,
    prepareHeaders: (headers, api) => {
      headers.set("X-Requested-With", "XMLHttpRequest");
      headers.set("x-brawn-api-version", "2021-09-17");
      headers.set("Accept", "application/json");
      const name = "XSRF-TOKEN";
      let cookie = {};
      decodeURIComponent(document.cookie).split(";").forEach(el => {
        let [k, v] = el.split("=");
        (cookie as any)[k.trim()] = v;
      });
      let token = (cookie as any)[name];
      if (token) {
        headers.set("X-XSRF-TOKEN", token);
      }
      return headers;
    },
    credentials: "include"
  }),
  tagTypes: ["Group"],
  endpoints: (builder) => ({
    searchGroups: builder.query({
      query: (term: string) => `/api/groups/search?term=${term}`,
      providesTags: ["Group"]
    }),
    getUserGroups: builder.query({
      query: (userId: number) => `/api/groups/user/${userId}`,
      providesTags: ["Group"]
    }),
    addNewGroup: builder.mutation({
      query: (body) => ({
        url: "/api/groups",
        method: "POST",
        body
      }),
      invalidatesTags: ["Group"]
    }),
    confirmGroupPairing: builder.mutation({
      query: (body) => ({
        url: "/api/gym/pairing",
        method: "PUT",
        body
      })
    }),
    getCountries: builder.query({
      query: () => `/api/countries`
    }),
    register: builder.mutation({
      query: (body) => ({
        url: "/register",
        method: "POST",
        body
      })
    }),
    sendForgotPasswordLink: builder.mutation({
      query: (body) => ({
        url: "/password/email",
        method: "POST",
        body
      })
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "/password/reset",
        method: "POST",
        body
      })
    })
  })
});

export const {
  useGetUserGroupsQuery,
  useGetCountriesQuery,
  useAddNewGroupMutation,
  useConfirmGroupPairingMutation,
  useRegisterMutation,
  useResetPasswordMutation,
  useSendForgotPasswordLinkMutation,
  useSearchGroupsQuery
} = brawnApi;

