import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { FieldInputProps, FieldMetaProps, FormikProps } from "formik/dist/types";

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = {
  current: null,
  places: null
};

interface AutocompleteSessionToken {

}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

export interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
}


export interface GoogleMapsProps {
  width?: number,
  label?: string,
  required?: boolean,
  onChange?: (result: GooglePlaceResult) => void,
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  meta?: FieldMetaProps<any>;
}

interface Location {
  lat: () => number,
  lng: () => number
}

export interface GooglePlaceResult {
  place_id: string;
  geometry: {
    location: {
      lat: number,
      lng: number
    }
  };
}

export interface PlaceResult {
  place_id: string;
  geometry: {
    location: Location
  };
}

interface PlacesServiceStatus {

}

export default function GooglePlacesAutoComplete(props: GoogleMapsProps = { required: false, label: "Add a location" }) {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [sessionToken, setSessionToken] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string, sessionToken?: AutocompleteSessionToken },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        200
      ),
    []
  );

  const confirmPlace = React.useMemo(
    () =>
      (
        request: { placeId: string, sessionToken: string, fields: string[] },
        callback: (results: PlaceResult, status?: PlacesServiceStatus) => void
      ) => {
        (autocompleteService.places as any).getDetails(request, callback);
      }
    , []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
      autocompleteService.places = new (window as any).google.maps.places.PlacesService(document.getElementById("google-map"));
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    let newSessionToken = sessionToken;
    if (!sessionToken) {
      newSessionToken = new (window as any).google.maps.places.AutocompleteSessionToken();
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, sessionToken: newSessionToken }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
        setSessionToken(newSessionToken);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, sessionToken]);

  return (
    <>
      <div id={"google-map"} />
      <Autocomplete
        id="google-map-demo"
        sx={{ width: props.width }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          if (newValue?.place_id) {
            confirmPlace({ placeId: newValue.place_id, sessionToken, fields: ["geometry.location", "place_id"] }, (result, status) => {
              if (props.onChange) {
                props.onChange({
                  place_id: result.place_id,
                  geometry: {
                    location: {
                      lng: result.geometry.location.lng(),
                      lat: result.geometry.location.lat()
                    }
                  }
                });
              }
            });
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label={props.label} fullWidth required={props.required} />
        )}
        renderOption={(props, option) => {
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length])
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box
                    component={LocationOnIcon}
                    sx={{ color: "text.secondary", mr: 2 }}
                  />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400
                      }}
                    >
                    {part.text}
                  </span>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </>
  );
}
