import { LinkParams } from "../Interfaces";
import { Link as RouterLink } from "react-router-dom";
import { ButtonVariants } from "./ButtonConstants";

export function Link(props: LinkParams) {
  let { className, button, variant, block, ...rest } = props;
  if (button) {
    className = variant ? ButtonVariants[variant] : ButtonVariants.default;
  } else {
    className = ButtonVariants.link + " " + className;
  }
  if (block) {
    className += " block";
  }
  return (<RouterLink {...rest} className={className}>{props.children}</RouterLink>);
}
