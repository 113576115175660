import apiClient from "./apiClient";
import { User } from "../Interfaces";

const login = (email: string, password: string) => {
  return apiClient.get("/sanctum/csrf-cookie").then(() => {
    return apiClient.post("/login", { email, password })
      .then(data => data.data);
  });
};

const storeUser = (user: User) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const retrieveUserFromStorage = (): User => {
  let item = localStorage.getItem("user");
  return item ? JSON.parse(item) : null;
};

export const authService = { login, storeUser, retrieveUserFromStorage };
