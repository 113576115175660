import Heading from "../../ui/Heading";
import { Routes } from "../../RouteConstants";
import { Link } from "../../ui/Link";
import { TextField } from "formik-mui";
import { Field, Form, Formik } from "formik";
import { Button } from "@mui/material";
import { object, string } from "yup";
import { useSendForgotPasswordLinkMutation } from "../../services/apiClient";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";

interface Values {
  email: string;
}

type SetSubmitting = (a: boolean) => void;

function ForgotPassword() {
  const [sent, setSent] = React.useState(false);
  const [sendForgotPasswordLink, { isLoading }] = useSendForgotPasswordLinkMutation();
  const submit = (values: Values, { setSubmitting }: { setSubmitting: SetSubmitting }) => {
    if (!isLoading) {
      try {
        sendForgotPasswordLink(values)
          .unwrap()
          .then(() => {
            setSubmitting(false);
            setSent(true);
          })
          .catch(e => console.error(e));
      } catch (e) {
        console.error(e);
      }
    }
  };
  let className = "grow flex flex-col justify-center align-middle max-w-md mx-auto w-full";
  return (
    <div className={"flex flex-col min-h-screen"}>
      <Heading>Forgot Password</Heading>
      {sent &&
        <div className={className}>
          <div className={"grow flex flex-col mt-4 justify-center text-black"}>
            <EmailIcon className={"text-red-500 mx-auto mb-8"} sx={{ fontSize: "4rem" }} />
            <h3 className={"text-xl font-bold mb-2 text-red-500 text-center"}>Reset Link Sent</h3>
            <p className={"mb-2 text-center"}>Check your inbox for instructions on how to reset your password.</p>
          </div>
        </div>
      }
      {!sent &&
        <Formik
          initialValues={{ email: "" }}
          validationSchema={object({
            email: string().required("Email address is required").email("Email address must be a valid email address")
          })}
          onSubmit={submit}
        >
          {({ isSubmitting }) => {

            return (
              <Form className={className}>
                <div className={"grow flex flex-col justify-center align-middle"}>
                  <Field component={TextField} id={"forgotPasswordEmailAddress"} name={"email"} label="Email address" variant="outlined" type={"email"} required fullWidth
                         className={"mb-4"} />
                  <Link to={Routes.LOGIN} className={"text-center p-3 mx-auto block"}>Back to login</Link>
                </div>
                <div className={"py-5"}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={isSubmitting}
                    data-testid={"submit-reset-link-request-button"}
                    fullWidth
                    type={"submit"}
                    size={"large"}
                  >Continue</Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      }
    </div>
  );
}

export default ForgotPassword;
