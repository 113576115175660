import Heading from "../../ui/Heading";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import { Button } from "@mui/material";
import { object, ref, string } from "yup";
import { useResetPasswordMutation } from "../../services/apiClient";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Routes } from "../../RouteConstants";
import { useSnackbar } from "notistack";

interface Values {
  password: string;
  password_confirmation: string;
  token?: string;
  email?: string;
}

function ResetPassword() {
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  let navigate = useNavigate();
  let { token } = useParams();
  let [searchParams] = useSearchParams();
  let email = searchParams.get("email") ?? "";
  const { enqueueSnackbar } = useSnackbar();
  const submit = (values: Values, { setSubmitting }: FormikHelpers<any>) => {
    if (!isLoading) {
      values.token = token;
      values.email = email;
      resetPassword(values).unwrap()
        .then(() => {
          enqueueSnackbar("Password successfully reset");
          navigate(Routes.LOGIN);
        });
    }
  };

  return (
    <div className={"flex flex-col min-h-screen"}>
      <Heading>Reset Password</Heading>
      <Formik
        initialValues={{ password: "", password_confirmation: "" }}
        validationSchema={object({
          password: string().required("Password is required"),
          password_confirmation: string().required("Please confirm your password").oneOf([ref("password"), null], "Passwords don't match")
        })}
        onSubmit={submit}
      >
        {({ submitForm, isSubmitting }) => (
          <Form className={"grow flex flex-col justify-center align-middle max-w-md mx-auto w-full"}>
            <div className={"grow flex flex-col justify-center align-middle"}>
              <Field component={TextField} id={"forgotPasswordPassword"} name={"password"} label="Password" variant="outlined" type={"password"} required fullWidth
                     margin={"dense"} />
              <Field component={TextField} id={"forgotPasswordConfirmPassword"} name={"password_confirmation"} label="Confirm password" variant="outlined" type={"password"}
                     required
                     fullWidth
                     margin={"dense"} />
            </div>
            <div className={"py-5"}>
              <Button
                variant="outlined"
                color="primary"
                disabled={isSubmitting}
                fullWidth
                data-testid={'reset-password-submit-button'}
                size={"large"}
                type={"submit"}
              >Reset Password</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ResetPassword;
