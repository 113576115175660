import { Button } from "@mui/material";
import React from "react";
import Heading from "../ui/Heading";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { removeGroup, selectPairedGroup } from "../slices/pairedGroup";
import { useNavigate } from "react-router-dom";
import { Routes } from "../RouteConstants";
import { useConfirmGroupPairingMutation } from "../services/apiClient";
import { hasError, removeCode, selectPairCode } from "../slices/pairCodeSlice";

function ConfirmGroup() {
  let group = useAppSelector(selectPairedGroup);
  let code = useAppSelector(selectPairCode);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [confirmGroupPairing] = useConfirmGroupPairingMutation();
  let handleCancel = () => {
    dispatch(removeGroup());
    navigate(Routes.ATTACH_GROUP);
  };
  let handleConfirm = () => {
    confirmGroupPairing({
      group_id: group?.id,
      code: code
    })
      .unwrap()
      .then(() => {
        navigate(Routes.TABLET_PAIRED);
      })
      .catch(() => {
        dispatch(hasError());
        dispatch(removeCode());
      });
  };
  React.useEffect(() => {
    if (!group) {
      navigate(Routes.ATTACH_GROUP);
    }
  }, [group, navigate]);

  return (
    <div className={"flex flex-col min-h-screen"}>
      <Heading>Confirmation</Heading>
      <div className={"grow flex flex-col justify-center align-middle max-w-md mx-auto w-full"}>
        <div className={"grow flex flex-col mt-4 justify-center text-black"}>
          <h3 className={"text-xl font-bold mb-2"}>Group selected</h3>
          <p className={"mb-2"}>The following gym group will be connected to your in-gym device:</p>
          <h3 className={"text-xl font-bold text-red-500"}>{group?.display_name}</h3>
        </div>
        <div className="py-5">
          <div className="mb-3">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size={"large"}
              data-testid={"confirm-attach-group-button"}
              onClick={handleConfirm}
            >
              Connect
            </Button>
          </div>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            size={"large"}
            onClick={handleCancel}
          >
            Search Groups
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmGroup;
