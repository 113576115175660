import { Routes } from "../../RouteConstants";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ButtonVariants } from "../../ui/ButtonConstants";
import { object, string } from "yup";
import { useAppDispatch } from "../../app/hooks";
import { login } from "../../slices/authSlice";
import React from "react";

interface Values {
  email: string;
  password: string;
}

function Login() {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  let [error, setError] = React.useState("");
  const submit = (values: Values, { setSubmitting }: FormikHelpers<any>) => {
    dispatch(login(values))
      .unwrap()
      .then((response: any) => {
        setSubmitting(false);
        if (response.type === "auth/login/fulfilled") {
          navigate(Routes.ATTACH_GROUP);
        }
      })
      .catch(e => {
        let error = "These credentials do not match our records.";
        setError(error);
        setSubmitting(false);
      });
  };
  return (<div className={"flex flex-col min-h-screen"}>
    <div className={"grow flex justify-center align-middle"}>
      <img src={"/logo.png"} alt={"Brawn"} className={"m-auto"} />
    </div>
    <div className={"grow flex justify-center align-middle"}>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={object({
          email: string().required("Email address is required").email("Email address must be a valid email address"),
          password: string().required("Password is required")
        })}
        onSubmit={submit}
      >
        {({ submitForm, isSubmitting }) => (
          <Form className={"max-w-md"}>
            {error &&
              <p className={"text-red-500"}>{error}</p>
            }
            <Field component={TextField} data-testid={"login-email"} id={"loginEmailAddress"} name={"email"} label="Email address" variant="outlined" type={"email"} required
                   fullWidth margin={"dense"} />
            <Field component={TextField} data-testid={"login-email"} id={"loginPassword"} name={"password"} label="Password" variant="outlined" type={"password"} required fullWidth
                   margin={"dense"} />
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size={"large"}
              sx={{ mt: 1 }}
              type={"submit"}
              data-testid={"login-submit"}
            >
              Login
            </Button>
            <Link to={Routes.FORGOT_PASSWORD} className={"text-center p-3 mt-4 mx-auto block " + ButtonVariants.link} data-testid={"reset-password-link"}>Reset Password</Link>
          </Form>
        )}
      </Formik>
    </div>
    <div className={"py-5 max-w-md mx-auto w-full"}>
      <Link to={Routes.REGISTER} data-testid={"register-link"}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          size={"large"}
        >
          Create Account
        </Button>
      </Link>
    </div>
  </div>);
}

export default Login;
