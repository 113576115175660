import React from "react";
import AppRouter from "./AppRouter";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FD2B12"
    }
  },
  typography: {
    fontFamily: ["'Barlow'", "sans-serif"].join(",")
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <div className="mx-auto px-16 bg-white">
          <AppRouter />
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
