import { Navigate, useLocation } from "react-router-dom";
import { Routes } from "./RouteConstants";
import { useAppSelector } from "./app/hooks";
import { selectPairCode } from "./slices/pairCodeSlice";

function RequirePairCode({ children }: { children: JSX.Element }) {
  const code = useAppSelector(selectPairCode);
  let location = useLocation();

  if (!code) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={Routes.SAVE_PAIR_CODE} state={{ from: location }} replace />;
  }

  return children;
}

export default RequirePairCode;
