import { User } from "../Interfaces";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authService } from "../services/auth.service";
import { RootState } from "../app/store";

export interface AuthState {
  user: User,
  status: "idle" | "loading" | "failed",
  error: string | null
}

const initialState: AuthState = {
  user: authService.retrieveUserFromStorage(),
  status: "idle",
  error: null
};

interface LoginParams {
  email: string,
  password: string
}

export const login = createAsyncThunk(
  "auth/login",
  async (params: LoginParams) => await authService.login(params.email, params.password)
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
        state.error = null;
        authService.storeUser(action.payload);
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.error && action.error.message) ?? "Error";
      });
  }
});

export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
