import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./routes/auth/Login";
import Register from "./routes/auth/Register";
import ForgotPassword from "./routes/auth/ForgotPassword";
import ResetPassword from "./routes/auth/ResetPassword";
import CreateGroup from "./routes/CreateGroup";
import AttachGroup from "./routes/AttachGroup";
import { Routes as Paths } from "./RouteConstants";
import RequireAuth from "./RequireAuth";
import GuestRoute from "./GuestRoute";
import ConfirmGroup from "./routes/ConfirmGroup";
import SavePairCode from "./routes/SavePairCode";
import RequirePairCode from "./RequirePairCode";
import GroupTabletPaired from "./routes/GroupTabletPaired";

function AppRouter() {
  let login = <GuestRoute><Login /></GuestRoute>;
  return (
    <Router>
      <Routes>
        <Route path={Paths.HOME} element={login} />
        <Route path={Paths.LOGIN} element={login} />
        <Route path={Paths.REGISTER} element={<GuestRoute><Register /></GuestRoute>} />
        <Route path={Paths.FORGOT_PASSWORD} element={<GuestRoute><ForgotPassword /></GuestRoute>} />
        <Route path={Paths.RESET_PASSWORD} element={<GuestRoute><ResetPassword /></GuestRoute>} />
        <Route path={Paths.CREATE_GROUP} element={<RequireAuth><RequirePairCode><CreateGroup /></RequirePairCode></RequireAuth>} />
        <Route path={Paths.ATTACH_GROUP} element={<RequireAuth><RequirePairCode><AttachGroup /></RequirePairCode></RequireAuth>} />
        <Route path={Paths.CONFIRM_GROUP} element={<RequireAuth><RequirePairCode><ConfirmGroup /></RequirePairCode></RequireAuth>} />
        <Route path={Paths.SAVE_PAIR_CODE} element={<RequireAuth><SavePairCode /></RequireAuth>} />
        <Route path={Paths.TABLET_PAIRED} element={<RequireAuth><GroupTabletPaired /></RequireAuth>} />
        <Route path="*" element={login} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
